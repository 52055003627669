import React, { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@/common/components/link';
import { FOOTER_LINK } from '@/common/constants';
import { styles } from './styles';

export const Footer: FC<HTMLAttributes<HTMLElement>> = () => {
  const [t] = useTranslation();
  return (
    <div css={styles} className='footer__container'>
      <div className='footer__items'>
        <div className='footer__item'>
          <Link theme='grey' className='item' target='_blank' href={FOOTER_LINK.help}>
            {t('footer.help')}
          </Link>
        </div>

        <div className='footer__item'>
          <Link theme='grey' className='item' target='_blank' href={FOOTER_LINK.privacy}>
            {t('footer.privacy')}
          </Link>
        </div>

        <div className='footer__item'>
          <Link theme='grey' className='item' target='_blank' href={FOOTER_LINK.terms}>
            {t('footer.terms')}
          </Link>
        </div>
      </div>
    </div>
  );
};
