import { Enum } from '@/common/utils';
import { RoutePaths } from '../../navigation.models';
import { rootRoutePaths } from '../root';

export type authenticatedRoutes = Enum<typeof authenticatedRoutes>;
export const authenticatedRoutes = Enum('VOUCHER_REDEEM_FORM', 'VOUCHER_MAP_REDEEM_SUCCESS', 'VOUCHER_SUBSCRIPTION_REDEEM_SUCCESS');

export const authenticatedRoutePaths: RoutePaths<authenticatedRoutes> = {
  [authenticatedRoutes.VOUCHER_REDEEM_FORM]: `${rootRoutePaths.AUTHENTICATED}/redeem-voucher-form`,
  [authenticatedRoutes.VOUCHER_MAP_REDEEM_SUCCESS]: `${rootRoutePaths.AUTHENTICATED}/redeem-map-success`,
  [authenticatedRoutes.VOUCHER_SUBSCRIPTION_REDEEM_SUCCESS]: `${rootRoutePaths.AUTHENTICATED}/redeem-subscription-success`,
};
