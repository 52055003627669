// tslint:disable:react-hooks-nesting
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BehaviorSubject } from 'rxjs';

import { redirect } from '@/common/utils';
import { getLoginPageUrl } from '@/core/services/authentication';
import { makeGetUserData } from '@/store/authentication';

type exportedUserData = {
  firstName: string;
  lastName: string;
  email: string;
};

const ERROR_MSG = 'User is not logged in';

interface Window {
  osUserData: {
    getData: () => Promise<exportedUserData | Error>;
    sessionStream: BehaviorSubject<exportedUserData | Error>;
    logIn: () => void;
  };
}

declare const window: Window;

const getUserData = makeGetUserData();

export const userSessionSnippet = () => {
  const userData = useSelector(getUserData);

  const userSessionData = useMemo(
    () =>
      userData
        ? {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
          }
        : new Error(ERROR_MSG),
    [userData]
  );

  const filter$ = new BehaviorSubject(userSessionData);

  const osUserData = useMemo<Window['osUserData']>(
    () => ({
      getData: async () => userSessionData,
      sessionStream: filter$,
      logIn: async () => redirect(getLoginPageUrl()),
    }),
    [userSessionData]
  );

  useEffect(() => {
    filter$.next(userSessionData);
  }, [userSessionData]);

  window.osUserData = osUserData;
};
