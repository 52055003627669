import React, { FC, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { LoadingSection } from '@/common/components/loading-section';
import { userSessionSnippet } from '@/features/snippets/user-session-snippet';
import { makeGetLoadingSectionActive } from '@/store/interface';
import { Footer } from '../footer';
import { Header } from '../header';
import { LoadingIndicatorBar } from '../loading-indicator-bar';
import { PageWrapper } from '../page-wrapper';
import { TopBar } from '../top-bar';
import { loadingSectionStyles, styles } from './styles';

const getLoadingSectionActive = makeGetLoadingSectionActive();

export const useRouterScrollToTop = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);
};

export const Layout: FC = ({ children }) => {
  const loadingSectionActive = useSelector(getLoadingSectionActive);
  useRouterScrollToTop();
  userSessionSnippet();
  return (
    <div css={styles}>
      <Suspense fallback={<LoadingSection />}>
        {loadingSectionActive ? <LoadingSection css={loadingSectionStyles} /> : null}

        <TopBar />

        <div className='main'>
          <Header />

          <div className='main__container'>
            <PageWrapper>{children}</PageWrapper>
          </div>

          <Footer />
        </div>

        <div className='brand__container'>
          <div className='brand__color--first'></div>
          <div className='brand__color--second'></div>
          <div className='brand__color--third'></div>
        </div>

        <LoadingIndicatorBar />
      </Suspense>
    </div>
  );
};
