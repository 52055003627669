export const APP_STORE_APP_URL = 'https://apps.apple.com/gb/app/os-maps-walking-bike-trails/id978307846';
export const GOOGLE_PLAY_APP_URL = 'https://play.google.com/store/apps/details?id=uk.co.ordnancesurvey.osmaps';
export const CONTACT_URL = 'https://www.ordnancesurvey.co.uk/contact-us';
export const EXPLORE_URL = 'https://explore.osmaps.com';

export const FOOTER_LINK = {
  help: 'https://shop.ordnancesurvey.co.uk/mobile-download-faq.html#claim',
  privacy: 'https://www.ordnancesurvey.co.uk/privacy.html',
  terms: 'https://www.ordnancesurvey.co.uk/legal',
};
